<template>
  <div class="tags">
    <div v-for="(tag, i) in sortedTags" :key="i" class="tags-item">
      <plain-button class="tags-item__btn" @click="clickTag(tag)">
        <main-label
          :color="filtredTags.includes(tag) ? 'primary' : 'note'"
          theme="tags"
          class="tags-item__tag"
        >
          <!--        <plain-button class="tags-item__btn" @click="clickTag(tag)">-->
          {{ tag }}
          <!--        </plain-button>-->
        </main-label>
      </plain-button>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import MainLabel from '@/components/Labels/MainLabel';
import sortMapped from '@/utils/sortMapped';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Tags',
  components: { MainLabel },
  mixins: [setFocus],
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    filtredTags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sortedTags: [],
    };
  },
  computed: {},
  watch: {
    tags: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal.length && newVal !== oldVal) this.sortedTags = newVal.sort(sortMapped);
      },
      immediate: true,
    },
  },
  mounted() {},

  methods: {
    clickTag(event) {
      this.$emit('click-tag', event);
    },
    resetTag() {
      this.$emit('reset');
      // this.selectedTags = [];
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "reset": "Сбросить все теги"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tags {
  margin-top: 0.4rem;
  display flex
  flex-wrap: wrap;

  &-item {
    margin: 0.05rem 0 0.25rem 0.05rem
    &__tag {
      &:hover {
        background: $color-blue.medium
      }
    }
    &__btn {
      &:nth-child(1) {
      //margin-left: -0.5rem;
        //margin-top: 20px;
      }
    }

  }
}
</style>
